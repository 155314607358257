import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'front',
    component: () => import('../views/front/FrontView.vue'),
    redirect: 'front/home',
    children: [
      { path: 'front/home', name: 'frontHome' , component: () => import('../views/front/HomeView.vue')},
      { path: 'front/person', name: 'frontPerson' , component: () => import('../views/front/PersonView.vue')},
      { path: 'front/newsDetail', name: 'frontNewsDetail' , component: () => import('../views/front/NewsDetailView.vue')},
      { path: 'front/password', name: 'frontPassword' , component: () => import('../views/front/PasswordView.vue')},
      { path: 'front/newBlog', name: 'newBlog' , component: () => import('../views/front/newBlog.vue')},
    ]
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/manager/ManagerView.vue'),
    redirect: 'home',
    children: [
      { path: 'home', name: 'managerHome' , meta:{name:'系统首页'}, component: () => import('../views/manager/HomeView.vue') },
      { path: 'user', name: 'managerUser' , meta:{name:'用户管理'}, component: () => import('../views/manager/UserView.vue') },
      { path: 'notice', name: 'managerNotice' , meta:{name:'系统公告'}, component: () => import('../views/manager/NoticeView.vue') },
      { path: 'logs', name: 'managerLogs' , meta:{name:'系统日志'}, component: () => import('../views/manager/LogsView.vue') },
      { path: 'category', name: 'managerCategory' , meta:{name:'博客分类'}, component: () => import('../views/manager/CategoryView.vue') },
      { path: 'news', name: 'managerNews' , meta:{name:'博客信息'}, component: () => import('../views/manager/NewsView.vue') },
      { path: 'password', name: 'managerPassword' , meta:{name:'修改密码'}, component: () => import('../views/manager/PasswordView.vue') },
      { path: 'person', name: 'managerPerson' , meta:{name:'个人信息'}, component: () => import('../views/manager/PersonView.vue') },
      { path: 'im', name: 'managerIm' , meta:{name:'聊天室'}, component: () => import('../views/manager/Im.vue') },
    ]
  },
  {
    path: '/403', name: '403', component: () => import('../views/403.vue'),
  },
  {
    path: '*', name: '404', component: () => import('../views/404.vue'),
  },
  {
    path: '/login', name: 'login', component: () => import('../views/LoginView.vue'),
  },
  {
    path: '/register', name: 'register', component: () => import('../views/RegisterView.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 路由守卫
router.beforeEach((to ,from, next) => {
  // to 是到达的路由信息
  // from 是开源的路由信息
  // next 是帮助我们跳转路由的函数
  const user = localStorage.getItem("user");
  if (!user && to.path !== '/login' && to.path !== '/register') {
    return next("/login");
  }
  //如果不是管理员就不能通过路由访问
  if (to.path === '/home' && JSON.parse(user).role !== '管理员'){
    return next('/front/home')
  }
  next();
})

export default router
