import Vue from 'vue'
import App from './App.vue'
import router from './router'
//引入全局样式
import '@/assets/style.css'
//饿了么插件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//引入自定义axios
import request from "@/utils/request";
//引入cookie
import Cookies from "js-cookie";
//引入Cookies
Vue.use(Cookies);
//引入icon
import '@/assets/font_4532681_f1cidtgwmqd/iconfont.css'
//全局组件
Vue.prototype.$request = request
//全局设置组件变小，也可以不设置
Vue.use(ElementUI,{size : 'small'});
Vue.prototype.$bus = new Vue(); // 创建事件总线
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
